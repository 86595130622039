import * as actionTypes from "../constants";

export const initialState = {
  loading: "",
  error: "",
  notification: "",
  newNotification: "",
  notificationError: ""
};

export default function NotifyReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_INFO_TO_NOTICE_BOARD_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_INFO_TO_NOTICE_BOARD_SUCCESS:
      return {
        ...state,
        newNotification: action.payload
      };
    case actionTypes.ADD_INFO_TO_NOTICE_BOARD_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        notificationError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_INFO_ON_NOTICE_BOARD_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_INFO_ON_NOTICE_BOARD_SUCCESS:
      return {
        ...state,
        notification: action.payload
      };
    case actionTypes.GET_ALL_INFO_ON_NOTICE_BOARD_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        notificationError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
