import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import themes, { overrides } from "./themes";
import { history } from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import { PrivateRoute, PublicRoute } from "./utils/RouteComponent";
import Loader from "./utils/loader";
import { lifecycle } from "recompose";
import { connect } from "react-redux";
import { getUserDetailsById } from "./redux/actions/user";

const theme = createMuiTheme({ ...themes.default, ...overrides });

function WaitingComponent(Component) {
  return props => (
    <React.Suspense fallback={<Loader />}>
      <Component {...props} />
    </React.Suspense>
  );
}

const LoginCon = React.lazy(() => import("./views/Login/container"));
const Layout = React.lazy(() => import("./views/Layout"));

const Apps = ({ auth, user }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            Component={() => <Redirect to="/app/dashboard" />}
          />
          <PrivateRoute
            exact
            path="/app"
            Component={() => <Redirect to="/app/dashboard" />}
          />
          <PrivateRoute path="/app" Component={WaitingComponent(Layout)} />
          <PublicRoute path="/login" Component={WaitingComponent(LoginCon)} />
          <PublicRoute
            path="/register"
            Component={WaitingComponent(LoginCon)}
          />
          <PublicRoute
            path="/forget-password"
            Component={WaitingComponent(LoginCon)}
          />
          <Route component={() => <div>Not found</div>} />
        </Switch>
      </ConnectedRouter>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  persistUser: data => {
    dispatch(getUserDetailsById(data));
  }
});

const App = lifecycle({
  componentDidMount() {
    this._isMounted = true;
    if (localStorage.getItem("roasterToken")) {
      this.props.persistUser(localStorage.getItem("roasterUserId"));
    }
  },
  componentWillUnmount() {
    this._isMounted = false;
  }
})(Apps);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
