import * as actionTypes from "../constants";
import { toast } from "react-toastify";

export const initialState = {
  loading: "",
  error: "",
  userPersonalDevDetails: "",
  getUserPersonalDevDetailsError: false,
  addUserPersonalDevDetailsError: false,
  addUserPersonalDevDetails: false,
  getUserObservationRecordError: false,
  userObservationRecord: false,
  updateUserObservationRecordError: false,
  updateUserObservationRecord: false,
  addUserObservationRecordError: false,
  newUserObservationRecord: false
};

export default function FormReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DELETE_USER_PERSONAL_DEVELOPMENTS_PENDING:
      // toast.info("Saving User Personal Development Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_USER_PERSONAL_DEVELOPMENTS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        addUserPersonalDevDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_USER_PERSONAL_DEVELOPMENTS_FAILED:
      // toast.error("Failed to save user personal development details...");
      return {
        ...state,
        loading: false,
        error: true,
        addUserPersonalDevDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_USER_PERSONAL_DEVELOPMENT_PENDING:
      // toast.info("Saving User Personal Development Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_USER_PERSONAL_DEVELOPMENT_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        addUserPersonalDevDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_USER_PERSONAL_DEVELOPMENT_FAILED:
      // toast.error("Failed to save user personal development details...");
      return {
        ...state,
        loading: false,
        error: true,
        addUserPersonalDevDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_USER_PERSONAL_DEVELOPMENT_PENDING:
      toast.info("Saving User Personal Development Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_USER_PERSONAL_DEVELOPMENT_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        addUserPersonalDevDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_NEW_USER_PERSONAL_DEVELOPMENT_FAILED:
      toast.error("Failed to save user personal development details...");
      return {
        ...state,
        loading: false,
        error: true,
        addUserPersonalDevDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_PERSONAL_DEVELOPMENTS_PENDING:
      toast.info("Fetching User Personal Development Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_PERSONAL_DEVELOPMENTS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        userPersonalDevDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_PERSONAL_DEVELOPMENTS_FAILED:
      toast.error("Failed to fetch user personal development details...");
      return {
        ...state,
        loading: false,
        error: true,
        getUserPersonalDevDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.SAVE_USER_OBSERVATION_RECORD_PENDING:
      toast.info("Saving User Observation Record Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.SAVE_USER_OBSERVATION_RECORD_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        newUserObservationRecord: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.SAVE_USER_OBSERVATION_RECORD_FAILED:
      toast.error("Failed to save user observation record details...");
      return {
        ...state,
        loading: false,
        error: true,
        addUserObservationRecordError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_USER_OBSERVATION_RECORD_PENDING:
      toast.info("Updating User Observation Record Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_USER_OBSERVATION_RECORD_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        updateUserObservationRecord: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_USER_OBSERVATION_RECORD_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateUserObservationRecordError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_OBSERVATION_RECORD_PENDING:
      toast.info("Fetching User Observation Record Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_OBSERVATION_RECORD_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        userObservationRecord: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_OBSERVATION_RECORD_FAILED:
      toast.error("Failed to fetch user observation record details...");
      return {
        ...state,
        loading: false,
        error: true,
        getUserObservationRecordError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
