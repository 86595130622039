import * as actionTypes from "../constants";
import { toast } from "react-toastify";

export const initialState = {
  loading: "",
  error: "",
  Roaster: "",
  newRoaster: "",
  updatedRoasterComplete: "",
  updatedRoasterOngoing: "",
  swapRoaster: "",
  UserRoasterTotalHours: "",
  AllRoasterTotalHours: "",
  RoasterError: ""
};

export default function RoasterReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DELETE_USER_ROASTER_DETAILS_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_USER_ROASTER_DETAILS_SUCCESS:
      toast.success("Roaster Deleted");
      return {
        ...state,
        newRoaster: action.payload
      };
    case actionTypes.DELETE_USER_ROASTER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_USER_ROASTER_DETAILS_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_USER_ROASTER_DETAILS_SUCCESS:
      toast.success("New Roaster Added");
      return {
        ...state,
        newRoaster: action.payload
      };
    case actionTypes.ADD_NEW_USER_ROASTER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_USER_ROASTER_DETAILS_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_USER_ROASTER_DETAILS_SUCCESS:
      toast.success("Roaster Updated");
      return {
        ...state,
        newRoaster: action.payload
      };
    case actionTypes.UPDATE_USER_ROASTER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_ROASTERS_SHIFT_BY_DATE_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_ROASTERS_SHIFT_BY_DATE_SUCCESS:
      return {
        ...state,
        Roaster: action.payload
      };
    case actionTypes.GET_ALL_ROASTERS_SHIFT_BY_DATE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_USER_ROASTERS_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_USER_ROASTERS_SUCCESS:
      return {
        ...state,
        Roaster: action.payload
      };
    case actionTypes.GET_ALL_USER_ROASTERS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_ROASTER_TO_COMPLETED_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_ROASTER_TO_COMPLETED_SUCCESS:
      return {
        ...state,
        updatedRoasterComplete: action.payload
      };
    case actionTypes.UPDATE_ROASTER_TO_COMPLETED_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_ROASTER_TO_ONGOING_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_ROASTER_TO_ONGOING_SUCCESS:
      return {
        ...state,
        updatedRoasterOngoing: action.payload
      };
    case actionTypes.UPDATE_ROASTER_TO_ONGOING_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.SWAP_ROASTERS_PENDING:
      toast.info("loading....");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.SWAP_ROASTERS_SUCCESS:
      toast.success("Roaster Swap Successful");
      return {
        ...state,
        swapRoaster: action.payload
      };
    case actionTypes.SWAP_ROASTERS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_ROASTER_TOTAL_HOURS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_ROASTER_TOTAL_HOURS_SUCCESS:
      return {
        ...state,
        UserRoasterTotalHours: action.payload
      };
    case actionTypes.GET_USER_ROASTER_TOTAL_HOURS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_ROASTERS_TOTAL_HOURS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_ROASTERS_TOTAL_HOURS_SUCCESS:
      return {
        ...state,
        AllRoasterTotalHours: action.payload
      };
    case actionTypes.GET_ALL_ROASTERS_TOTAL_HOURS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RoasterError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
