import * as actionTypes from "../constants";
import { toast } from "react-toastify";

export const initialState = {
  loading: "",
  error: "",
  Complains: "",
  newComplains: "",
  staffComplains: "",
  clientComplains: "",
  ComplainsError: ""
};

export default function ComplainsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_NEW_COMPLAIN_PENDING:
      toast.info("Submitting complains Details..");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_COMPLAIN_SUCCESS:
      toast.success("Complains Submitted Successfully.");
      return {
        ...state,
        newComplains: action.payload
      };
    case actionTypes.ADD_NEW_COMPLAIN_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ComplainsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_COMPLAINS_PENDING:
      // toast.info('Deleteing Complains Details..')
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_COMPLAINS_SUCCESS:
      // toast.success('Complains Delected Successfully.')
      return {
        ...state,
        Complains: action.payload
      };
    case actionTypes.GET_ALL_COMPLAINS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ComplainsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_STAFFS_COMPLAINS_PENDING:
      // toast.info('Deleteing Complains Details..')
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_STAFFS_COMPLAINS_SUCCESS:
      // toast.success('Complains Delected Successfully.')
      return {
        ...state,
        staffComplains: action.payload
      };
    case actionTypes.GET_ALL_STAFFS_COMPLAINS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ComplainsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_CLIENTS_COMPLAINS_PENDING:
      // toast.info('Deleteing Complains Details..')
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_CLIENTS_COMPLAINS_SUCCESS:
      // toast.success('Complains Delected Successfully.')
      return {
        ...state,
        clientComplains: action.payload
      };
    case actionTypes.GET_ALL_CLIENTS_COMPLAINS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ComplainsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
