import * as actionTypes from "../constants";

export const initialState = {
  loading: "",
  error: "",
  groupChat: [],
  newGroupChat: [],
  groupChatMembers: [],
  newGroupChatMessage: [],
  newGroupChatImage: [],
  userGroupChat: [],
  newChat: [],
  newChatMessage: [],
  newChatImage: [],
  userChats: [],
  userChatsViaId: [],
  groupChatError: [],
  ChatError: []
};

export default function ChatReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_NEW_GROUP_CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.CREATE_NEW_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        newGroupChat: action.payload.data
      };
    case actionTypes.CREATE_NEW_GROUP_CHAT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        groupChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_GROUP_CHAT_PARTICIPANT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_GROUP_CHAT_PARTICIPANT_SUCCESS:
      return {
        ...state,
        GroupChatMembers: action.payload
      };
    case actionTypes.ADD_GROUP_CHAT_PARTICIPANT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        groupChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.POST_MESSAGE_IN_GROUP_CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.POST_MESSAGE_IN_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        newGroupChatMessage: action.payload
      };
    case actionTypes.POST_MESSAGE_IN_GROUP_CHAT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        groupChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.POST_IMAGE_IN_GROUP_CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.POST_IMAGE_IN_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        newGroupChatImage: action.payload
      };
    case actionTypes.POST_IMAGE_IN_GROUP_CHAT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        groupChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_GROUP_CHATS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_GROUP_CHATS_SUCCESS:
      return {
        ...state,
        groupChat: action.payload
      };
    case actionTypes.GET_ALL_GROUP_CHATS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        groupChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_GROUP_CHATS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_GROUP_CHATS_SUCCESS:
      return {
        ...state,
        userGroupChat: action.payload
      };
    case actionTypes.GET_USER_GROUP_CHATS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        groupChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ESTABLISH_NEW_CONNECTION_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ESTABLISH_NEW_CONNECTION_SUCCESS:
      return {
        ...state,
        newChat: action.payload
      };
    case actionTypes.ESTABLISH_NEW_CONNECTION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.POST_MESSAGE_IN_TWO_USERS_CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.POST_MESSAGE_IN_TWO_USERS_CHAT_SUCCESS:
      return {
        ...state,
        newChatMessage: action.payload
      };
    case actionTypes.POST_MESSAGE_IN_TWO_USERS_CHAT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.POST_IMAGE_IN_TWO_USERS_CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.POST_IMAGE_IN_TWO_USERS_CHAT_SUCCESS:
      return {
        ...state,
        newChatImage: action.payload
      };
    case actionTypes.POST_IMAGE_IN_TWO_USERS_CHAT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_CHATS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_CHATS_SUCCESS:
      return {
        ...state,
        userChats: action.payload
      };
    case actionTypes.GET_USER_CHATS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_CHATS_BY_ID_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_CHATS_BY_ID_SUCCESS:
      return {
        ...state,
        userChatsViaId: action.payload
      };
    case actionTypes.GET_CHATS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ChatError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
