import * as actionTypes from "../constants";

export const initialState = {
  loading: "",
  error: "",
  Upload: "",
  newUpload: "",
  newUploadGetUrl: "",
  UploadError: ""
};

export default function UploadReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        newUpload: action.payload
      };
    case actionTypes.UPLOAD_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        UploadError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPLOAD_DOCUMENT_AND_GET_URL_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPLOAD_DOCUMENT_AND_GET_URL_SUCCESS:
      return {
        ...state,
        newUploadGetUrl: action.payload
      };
    case actionTypes.UPLOAD_DOCUMENT_AND_GET_URL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        UploadError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_UPLOADED_DOCUMENTS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_UPLOADED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        Upload: action.payload
      };
    case actionTypes.GET_ALL_UPLOADED_DOCUMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        UploadError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
