import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "jquery";
import "popper.js";
import "./assets/css/index.css";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from "react-toastify";
import ConnectivityListener from "./utils/Network";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./utils/ErrorBoundary";

import store from "./redux/store";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ToastContainer
        autoClose={3000}
        position="top-right"
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
      <ConnectivityListener />
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
