import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import LayoutReducer from "./env";
import authReducer from "./auth";
import userReducer from "./user";
import ClientsReducer from "./clients";
import ApplicationReducer from "./Application";
import ChatReducer from "./chat";
import FleetReducer from "./Fleet";
import NotifyReducer from "./notify";
import PolicyReducer from "./Policy";
import RequestReducer from "./Request";
import RoasterReducer from "./Roaster";
import SuppliesReducer from "./Supplies";
import TrainingReducer from "./Training";
import UploadReducer from "./Upload";
import FormReducer from "./forms";
import LocationReducer from "./location";
import PayrollReducer from "./Payment";
import ComplainsReducer from "./Complains";

// main reducers
const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    layout: LayoutReducer,
    auth: authReducer,
    clients: ClientsReducer,
    Application: ApplicationReducer,
    chat: ChatReducer,
    Fleet: FleetReducer,
    Notify: NotifyReducer,
    Policy: PolicyReducer,
    Request: RequestReducer,
    Roaster: RoasterReducer,
    Supplies: SuppliesReducer,
    Training: TrainingReducer,
    Upload: UploadReducer,
    user: userReducer,
    form: FormReducer,
    location: LocationReducer,
    payroll: PayrollReducer,
    complains: ComplainsReducer
    // your reducer here
  });

export default rootReducer;
