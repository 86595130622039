/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import { toast } from "react-toastify";

class ConnectivityListener extends Component {
  state = { isOnline: window ? window.navigator.onLine : false };

  componentDidMount() {
    window.addEventListener("online", this.onLine, false);
    window.addEventListener("offline", this.offLine, false);
  }
  componentWillUnmount() {
    window.removeEventListener("online", this.onLine);
    window.removeEventListener("offline", this.offLine);
  }
  onLine = () => {
    this.setState({ isOnline: true });
  };
  offLine = () => {
    this.setState({ isOnline: false });
  };

  onlineCallback = () => {
    this.offlineToastId = null;
  };
  offlineCallback = id => {
    this.offlineToastId = id;
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { isOnline } = this.state;

    if (prevState.isOnline !== isOnline) {
      return { isOnline };
    }

    return null;
  }

  componentDidUpdate(props, state, snapshot) {
    if (!snapshot) return;

    const { isOnline } = snapshot;

    const content = (
      <div>
        <strong>{isOnline ? "Online" : "Offline"}</strong>
        <div>
          {isOnline ? "Welcome, back online" : "Oops, you are offline!"}
        </div>
      </div>
    );
    {
      isOnline ? toast.success(content) : toast.error(content);
    }
  }
  render() {
    return null;
  }
}

export default ConnectivityListener;
