import * as actionTypes from "../constants";

export const initialState = {
  loading: "",
  error: "",
  Supplies: "",
  newSupplies: "",
  SuppliesTransaction: "",
  SuppliesError: ""
};

export default function SuppliesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_NEW_SUPPLIES_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_SUPPLIES_DETAILS_SUCCESS:
      return {
        ...state,
        newSupplies: action.payload
      };
    case actionTypes.ADD_NEW_SUPPLIES_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        SuppliesError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_TRANSACTIONS_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_TRANSACTIONS_DETAILS_SUCCESS:
      return {
        ...state,
        SuppliesTransaction: action.payload
      };
    case actionTypes.ADD_NEW_TRANSACTIONS_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        SuppliesError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_SUPPLIES_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_SUPPLIES_DETAILS_SUCCESS:
      return {
        ...state,
        Supplies: action.payload
      };
    case actionTypes.GET_ALL_SUPPLIES_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        SuppliesError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.DELETE_A_SUPPLIES_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_A_SUPPLIES_DETAILS_SUCCESS:
      return {
        ...state,
        deleteSupplies: action.payload
      };
    case actionTypes.DELETE_A_SUPPLIES_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        SuppliesError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
