import * as actionTypes from "../constants";
import { toast } from "react-toastify";

export const initialState = {
  loading: false,
  error: false,
  allStaffCoordinates: false,
  getAllStaffCoordinatesError: false
};

export default function LocationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_LOCATION_PENDING:
      toast.info("Fetching User Location Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_LOCATION_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        allStaffCoordinates: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_LOCATION_FAILED:
      toast.error("Failed to fetch all user coordinates...");
      return {
        ...state,
        loading: false,
        error: true,
        getAllStaffCoordinatesError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
