import * as actionTypes from "../constants";
import { toast } from "react-toastify";

export const initialState = {
  loading: "",
  error: "",
  Fleet: "",
  newFleet: "",
  UpdateFleet: "",
  unassignedFleet: "",
  FleetError: ""
};

export default function FleetReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DELETE_VEHICLE_DETAILS_PENDING:
      toast.info("Deleteing Fleet Details..");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_VEHICLE_DETAILS_SUCCESS:
      toast.success("Fleet Delected Successfully.");
      return {
        ...state,
        newFleet: action.payload
      };
    case actionTypes.DELETE_VEHICLE_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        FleetError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_VEHICLE_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        newFleet: action.payload
      };
    case actionTypes.ADD_NEW_VEHICLE_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        FleetError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_VEHICLE_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        UpdateFleet: action.payload
      };
    case actionTypes.UPDATE_VEHICLE_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        FleetError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_VEHICLES_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        Fleet: action.payload
      };
    case actionTypes.GET_ALL_VEHICLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        FleetError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_UNASSIGNED_VEHICLES_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_UNASSIGNED_VEHICLES_SUCCESS:
      return {
        ...state,
        unassignedFleet: action.payload
      };
    case actionTypes.GET_ALL_UNASSIGNED_VEHICLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        FleetError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
