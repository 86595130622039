import * as actionTypes from "../constants";

export const initialState = {
  loading: "",
  error: "",
  Policy: "",
  newPolicy: "",
  UpdatePolicy: "",
  PolicyError: ""
};

export default function PolicyReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_COMPANY_POLICY_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.CREATE_COMPANY_POLICY_SUCCESS:
      return {
        ...state,
        newPolicy: action.payload
      };
    case actionTypes.CREATE_COMPANY_POLICY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        PolicyError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_COMPANY_POLICY_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_COMPANY_POLICY_SUCCESS:
      return {
        ...state,
        Policy: action.payload
      };
    case actionTypes.GET_COMPANY_POLICY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        PolicyError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_COMPANY_POLICY_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_COMPANY_POLICY_SUCCESS:
      return {
        ...state,
        UpdatePolicy: action.payload
      };
    case actionTypes.UPDATE_COMPANY_POLICY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        PolicyError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
