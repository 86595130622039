import * as actionTypes from "../constants";
import { toast } from "react-toastify";

const initialState = {
  currentUser: {},
  resetAccout: "",
  changePassword: "",
  newUser: false,
  loading: false,
  error: false,
  signUpError: false,
  logInError: false,
  isUserLoggedInError: false,
  isUserLoggedIn: false,
  isRegistered: false,
  updatedProfile: false,
  recoverError: false,
  changeLoading: false,
  profileLoading: false,
  CheckUserLoading: false
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REGISTER_ADMIN_PENDING:
      toast.info("Creating Account...");
      return {
        ...state,
        currentUser: state.currentUser,
        loading: true,
        error: false
      };
    case actionTypes.REGISTER_ADMIN_SUCCESS:
      toast.success("Admin Account Created Successfully");
      return {
        ...state,
        currentUser: state.currentUser,
        loading: false,
        isRegistered: true,
        error: false,
        newUser: action.payload.data
      };
    case actionTypes.REGISTER_ADMIN_FAILED:
      toast.error(
        `An error occurred: ${
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
        }`
      );
      return {
        ...state,
        currentUser: state.currentUser,
        loading: false,
        signUperror:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.REGISTER_PENDING:
      toast.info("Creating Account...");
      return {
        ...state,
        currentUser: state.currentUser,
        loading: true,
        error: false
      };
    case actionTypes.REGISTER_SUCCESS:
      toast.success("Account Created Successfully");
      return {
        ...state,
        currentUser: state.currentUser,
        loading: false,
        isRegistered: true,
        error: false,
        newUser: action.payload.data
      };
    case actionTypes.REGISTER_FAILED:
      toast.error(
        `An error occurred: ${
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
        }`
      );
      return {
        ...state,
        currentUser: state.currentUser,
        loading: false,
        signUperror:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.LOGIN_PENDING:
      toast.info("Logging in...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.LOGIN_SUCCESS:
      toast.success("Login Successfully!");
      return {
        ...state,
        currentUser: action.payload.data,
        isUserLoggedIn: true,
        loading: false,
        error: false
      };
    case actionTypes.LOGIN_FAILED:
      toast.error(
        `An error occurred: ${
          action.payload.response === undefined
            ? action.payload.error
            : action.payload.response.data.error
        }`
      );
      return {
        ...state,
        loading: false,
        error: true,
        logInError:
          action.payload.response === undefined
            ? action.payload.error
            : action.payload.response.data.error
      };
    case actionTypes.GET_USER_BY_ID_PENDING:
      toast.info("Fetching Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_BY_ID_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        currentUser: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_BY_ID_FAILED:
      toast.error("Failed to fetch details...");
      return {
        ...state,
        loading: false,
        error: true,
        getUserDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.LOGOUT_PENDING:
      toast.info("Logging you out...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.LOGOUT_SUCCESS:
      toast.success("Logged out successfully!");
      return {
        ...state,
        currentUser: {},
        isUserLoggedIn: false,
        loading: false,
        error: false
      };
    case actionTypes.LOGOUT_FAILED:
      toast.error(`An error occurred: ${action.payload.message}`);
      return {
        ...state,
        loading: false,
        error: true,
        logoutError: action.payload.message
      };
    case actionTypes.FORGOT_PASSWORD_PENDING:
      toast.info("Loading...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      // toast.success("Logged out successfully!");
      return {
        ...state,
        // user: {},
        loading: false,
        error: false
      };
    case actionTypes.FORGOT_PASSWORD_FAILED:
      toast.error(`An error occurred: ${action.payload.response.data.error}`);
      return {
        ...state,
        loading: false,
        error: true,
        forgotPasswordError:
          action.payload.response === undefined
            ? action.payload.error
            : action.payload.response.data.error
      };
    case actionTypes.RESET_USER_PASSWORD_PENDING:
      toast.info("Resetting Password...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.RESET_USER_PASSWORD_SUCCESS:
      // toast.success("Logged out successfully!");
      return {
        ...state,
        // user: {},
        loading: false,
        error: false
      };
    case actionTypes.RESET_USER_PASSWORD_FAILED:
      toast.error(
        `An error occurred: ${
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
        }`
      );
      return {
        ...state,
        loading: false,
        error: true,
        resetPasswordError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.CHANGE_USER_PASSWORD_PENDING:
      toast.info("Changing Password...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      toast.success("Password Changed!");
      return {
        ...state,
        changePassword: action.payload,
        loading: false,
        error: false
      };
    case actionTypes.CHANGE_USER_PASSWORD_FAILED:
      toast.error(
        `An error occurred: ${
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
        }`
      );
      return {
        ...state,
        loading: false,
        error: true,
        changePasswordError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}

export default authReducer;
