import axios from "axios";
import * as actionTypes from "../constants";

axios.defaults.baseURL = actionTypes.BASE_URL;

const GetUserDetailsById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_USER_BY_ID_PENDING,
  });

  //  id must be user._id
  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/user/data/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_BY_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_BY_ID_FAILED,
        payload: err,
      });
    });
};

const GetAllUsersDetails = () => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_ALL_USERS_PROFILE_PENDING,
  });

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/user/all`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ALL_USERS_PROFILE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ALL_USERS_PROFILE_FAILED,
        payload: err,
      });
    });
};

const UpdateUserDetails = (data, id) => async (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_USER_DETAILS_PENDING,
  });

  const token = localStorage.getItem("roasterToken");
  const userId = id || localStorage.getItem("roasterUserId");
  await axios
    .put(`/user/update/details/${userId}`, data, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS_FAILED,
        payload: err,
      });
    });
};

const UpdateUserProfileImage = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_USER_PROFILE_IMAGE_PENDING,
  });

  const token = localStorage.getItem("roasterToken");
  const userId = localStorage.getItem("roasterUserId");
  await axios
    .post(`/user/update/picture/${userId}`, data, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PROFILE_IMAGE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PROFILE_IMAGE_FAILED,
        payload: err,
      });
    });
};

const UploadUserDocument = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.UPLOAD_USER_DOCUMENT_PENDING,
  });
  // data must be in this format
  // {
  //   "name": "string",
  //   "url": "string"
  // }

  const token = localStorage.getItem("roasterToken");
  const userId = localStorage.getItem("roasterUserId");
  await axios
    .post(`/user/upload/document/${userId}`, data, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.UPLOAD_USER_DOCUMENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPLOAD_USER_DOCUMENT_FAILED,
        payload: err,
      });
    });
};

const GetAllStaffsDetails = () => async (dispatch) => {
  //console.log(dispatch);
  dispatch({
    type: actionTypes.GET_ALL_STAFFS_PENDING,
  });

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/user/staffs`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ALL_STAFFS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ALL_STAFFS_FAILED,
        payload: err,
      });
    });
};

const SaveUserCoordinates = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_USER_COORDINATES_PENDING,
  });

  // data must be in this format:
  // <no format provided in doc>

  const token = localStorage.getItem("roasterToken");
  await axios
    .post(`/user/staff/track/coordinate/save`, data, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.SAVE_USER_COORDINATES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.SAVE_USER_COORDINATES_FAILED,
        payload: err,
      });
    });
};

const GetUserLocationById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_USER_LOCATION_PENDING,
  });

  // id is staff's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/user/staff/track/coordinate/get/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_LOCATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_LOCATION_FAILED,
        payload: err,
      });
    });
};

const AddUserBankDetails = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.ADD_USER_BANK_DETAILS_PENDING,
  });

  // data must be in this format:
  // {
  //   "userId": "string",
  //   "accountHoldersName": "string",
  //   "buildingSocietyName": "string",
  //   "buildingSocietyRollNumber": "string",
  //   "sortCode": "string",
  //   "accountNumber": "string",
  //   "allowWagesIntoAnotherPersonsAccount": true
  // }

  await axios
    .post("/user/bank", data)
    .then((response) => {
      dispatch({
        type: actionTypes.ADD_USER_BANK_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ADD_USER_BANK_DETAILS_FAILED,
        payload: err,
      });
    });
};

const GetUserBankDetailsById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_USER_BANK_DETAILS_PENDING,
  });

  //  id must be user's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/user/bank/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_BANK_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_BANK_DETAILS_FAILED,
        payload: err,
      });
    });
};

const SaveUserObservationRecord = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_USER_OBSERVATION_RECORD_PENDING,
  });

  // data must be in this format:
  // {
  //   "userId": "string",
  //   "timeIn": "string",
  //   "timeOut": "string",
  //   "supervisor": "string",
  //   "observationRecord": "string",
  //   "present": true
  // }

  await axios
    .post("/user/observation", data)
    .then((response) => {
      dispatch({
        type: actionTypes.SAVE_USER_OBSERVATION_RECORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.SAVE_USER_OBSERVATION_RECORD_FAILED,
        payload: err,
      });
    });
};

const UpdateUserObservationRecord =
  (observationId, data) => async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_USER_OBSERVATION_RECORD_PENDING,
    });

    // data must be in this format:
    // {
    //   "timeOut": "string",
    //   "observationRecord": "string"
    // }

    await axios
      .put(`/user/observation/${observationId}`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.UPDATE_USER_OBSERVATION_RECORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_USER_OBSERVATION_RECORD_FAILED,
          payload: err,
        });
      });
  };

const GetUserObservationRecordById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_USER_OBSERVATION_RECORD_PENDING,
  });

  //  id must be user's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/user/observation/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_OBSERVATION_RECORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_OBSERVATION_RECORD_FAILED,
        payload: err,
      });
    });
};

const AddNewUserMembership = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.ADD_NEW_USER_MEMBERSHIP_PENDING,
  });

  // data must be in this format:
  // {
  //   "userId": "string",
  //   "organisingBody": "string",
  //   "dateStarted": "string",
  //   "dateEnded": "string"
  // }

  await axios
    .post("/development/membership/save", data)
    .then((response) => {
      dispatch({
        type: actionTypes.ADD_NEW_USER_MEMBERSHIP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ADD_NEW_USER_MEMBERSHIP_FAILED,
        payload: err,
      });
    });
};

const GetUserMembershipsById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_USER_MEMBERSHIPS_PENDING,
  });

  //  id must be user's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/development/membership/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_MEMBERSHIPS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_MEMBERSHIPS_FAILED,
        payload: err,
      });
    });
};

const AddNewUserQualification = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.ADD_NEW_USER_QUALIFICATION_PENDING,
  });

  // data must be in this format:
  // {
  //   "userId": "string",
  //   "qualificationTitle": "string",
  //   "organisingBody": "string",
  //   "dateAttained": "string",
  //   "validUntill": "string",
  //   "certificateNumber": "string"
  // }

  await axios
    .post("/development/qualification/save", data)
    .then((response) => {
      dispatch({
        type: actionTypes.ADD_NEW_USER_QUALIFICATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ADD_NEW_USER_QUALIFICATION_FAILED,
        payload: err,
      });
    });
};

const GetUserQualificationsById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_USER_QUALIFICATIONS_PENDING,
  });

  //  id must be user's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/development/qualification/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_QUALIFICATIONS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_QUALIFICATIONS_FAILED,
        payload: err,
      });
    });
};

const AddNewUserPersonalDevRecord = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.ADD_NEW_USER_PERSONAL_DEVELOPMENT_PENDING,
  });

  // data must be in this format:
  // {
  //   "userId": "string",
  //   "what": "string",
  //   "why": "string",
  //   "how": "string",
  //   "when": "string",
  //   "actionTaken": "string",
  //   "priority": "string"
  // }

  await axios
    .post("/development/personal/save", data)
    .then((response) => {
      dispatch({
        type: actionTypes.ADD_NEW_USER_PERSONAL_DEVELOPMENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ADD_NEW_USER_PERSONAL_DEVELOPMENT_FAILED,
        payload: err,
      });
    });
};

const GetUserPersonalDevRecordById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_USER_PERSONAL_DEVELOPMENTS_PENDING,
  });

  //  id must be user's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .get(`/development/personal/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_PERSONAL_DEVELOPMENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_PERSONAL_DEVELOPMENTS_FAILED,
        payload: err,
      });
    });
};

const UpdateUserPersonalDevRecordById = (id, data) => async (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_USER_PERSONAL_DEVELOPMENT_PENDING,
  });

  //  id must be user's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .put(`/development/personal/update/${id}`, data, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PERSONAL_DEVELOPMENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PERSONAL_DEVELOPMENT_FAILED,
        payload: err,
      });
    });
};

const DeleteUserPersonalDevRecordById = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_USER_PERSONAL_DEVELOPMENTS_PENDING,
  });

  //  id must be user's id

  const token = localStorage.getItem("roasterToken");
  await axios
    .delete(`/development/personal/delete/${id}`, {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.DELETE_USER_PERSONAL_DEVELOPMENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.DELETE_USER_PERSONAL_DEVELOPMENTS_FAILED,
        payload: err,
      });
    });
};

export const deleteUserPersonalDevRecordById = (id) => (dispatch) => {
  dispatch(DeleteUserPersonalDevRecordById(id));
};

export const updateUserPersonalDevRecordById = (id, data) => (dispatch) => {
  dispatch(UpdateUserPersonalDevRecordById(id, data));
};

export const getUserDetailsById = (id) => (dispatch) => {
  dispatch(GetUserDetailsById(id));
};

export const getAllUsersDetails = () => (dispatch) => {
  dispatch(GetAllUsersDetails());
};

export const updateUserDetails = (data, id) => (dispatch) => {
  dispatch(UpdateUserDetails(data, id));
};

export const updateUserProfileImage = (data) => (dispatch) => {
  dispatch(UpdateUserProfileImage(data));
};

export const uploadUserDocument = (data) => (dispatch) => {
  dispatch(UploadUserDocument(data));
};

export const getAllStaffsDetails = () => (dispatch) => {
  dispatch(GetAllStaffsDetails());
};

export const saveUserCoordinates = (data) => (dispatch) => {
  dispatch(SaveUserCoordinates(data));
};

export const getUserLocationById = (id) => (dispatch) => {
  dispatch(GetUserLocationById(id));
};

export const addUserBankDetails = (data) => (dispatch) => {
  dispatch(AddUserBankDetails(data));
};

export const getUserBankDetailsById = (id) => (dispatch) => {
  dispatch(GetUserBankDetailsById(id));
};

export const saveUserObservationRecord = (data) => (dispatch) => {
  dispatch(SaveUserObservationRecord(data));
};

export const updateUserObservationRecord =
  (observationId, data) => (dispatch) => {
    dispatch(UpdateUserObservationRecord(observationId, data));
  };

export const getUserObservationRecordById = (id) => (dispatch) => {
  dispatch(GetUserObservationRecordById(id));
};

export const addNewUserMembership = (data) => (dispatch) => {
  dispatch(AddNewUserMembership(data));
};

export const getUserMembershipsById = (id) => (dispatch) => {
  dispatch(GetUserMembershipsById(id));
};

export const addNewUserQualification = (data) => (dispatch) => {
  dispatch(AddNewUserQualification(data));
};

export const getUserQualificationsById = (id) => (dispatch) => {
  dispatch(GetUserQualificationsById(id));
};

export const addNewUserPersonalDevRecord = (data) => (dispatch) => {
  dispatch(AddNewUserPersonalDevRecord(data));
};

export const getUserPersonalDevRecordById = (id) => (dispatch) => {
  dispatch(GetUserPersonalDevRecordById(id));
};
