export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:1007/api/v1"
    : "https://rotaapi.tokenfy.io/api/v1";

// env -done - confirm if it has either toaster or modal notification indicator in the reducers
export const TOGGLE_SIDEBAR = "Layout/TOGGLE_SIDEBAR";

//payemnt
export const GET_ALL_STAFF_PAYROLL_PENDING = "GET_ALL_STAFF_PAYROLL_PENDING";
export const GET_ALL_STAFF_PAYROLL_SUCCESS = "GET_ALL_STAFF_PAYROLL_SUCCESS";
export const GET_ALL_STAFF_PAYROLL_FAILED = "GET_ALL_STAFF_PAYROLL_FAILED";

export const GET_STAFF_PAYROLL_PENDING = "GET_STAFF_PAYROLL_PENDING";
export const GET_STAFF_PAYROLL_SUCCESS = "GET_STAFF_PAYROLL_SUCCESS";
export const GET_STAFF_PAYROLL_FAILED = "GET_STAFF_PAYROLL_FAILED";

export const GET_STAFF_PAYROLL_GRAPH_PENDING =
  "GET_STAFF_PAYROLL_GRAPH_PENDING";
export const GET_STAFF_PAYROLL_GRAPH_SUCCESS =
  "GET_STAFF_PAYROLL_GRAPH_SUCCESS";
export const GET_STAFF_PAYROLL_GRAPH_FAILED = "GET_STAFF_PAYROLL_GRAPH_FAILED";

// Auth -done - confirm if it has either toaster or modal notification indicator in the reducers
export const REGISTER_ADMIN_PENDING = "REGISTER_ADMIN_PENDING";
export const REGISTER_ADMIN_SUCCESS = "REGISTER_ADMIN_SUCCESS";
export const REGISTER_ADMIN_FAILED = "REGISTER_ADMIN_FAILED";

export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT_PENDING = "LOGOUT_PENDING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const RESET_USER_PASSWORD_PENDING = "RESET_USER_PASSWORD_PENDING";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_FAILED = "RESET_USER_PASSWORD_FAILED";

export const CHANGE_USER_PASSWORD_PENDING = "CHANGE_USER_PASSWORD_PENDING";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAILED = "CHANGE_USER_PASSWORD_FAILED";

// User - done - confirm if it has either toaster or modal notification indicator in the reducers
export const GET_ALL_USERS_PROFILE_PENDING = "GET_ALL_USERS_PROFILE_PENDING";
export const GET_ALL_USERS_PROFILE_SUCCESS = "GET_ALL_USERS_PROFILE_SUCCESS";
export const GET_ALL_USERS_PROFILE_FAILED = "GET_ALL_USERS_PROFILE_FAILED";

export const GET_USER_BY_ID_PENDING = "GET_USER_BY_ID_PENDING";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILED = "GET_USER_BY_ID_FAILED";

export const UPDATE_USER_DETAILS_PENDING = "UPDATE_USER_DETAILS_PENDING";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED";

export const UPDATE_USER_PROFILE_IMAGE_PENDING =
  "UPDATE_USER_PROFILE_IMAGE_PENDING";
export const UPDATE_USER_PROFILE_IMAGE_SUCCESS =
  "UPDATE_USER_PROFILE_IMAGE_SUCCESS";
export const UPDATE_USER_PROFILE_IMAGE_FAILED =
  "UPDATE_USER_PROFILE_IMAGE_FAILED";

export const UPLOAD_USER_DOCUMENT_PENDING = "UPLOAD_USER_DOCUMENT_PENDING";
export const UPLOAD_USER_DOCUMENT_SUCCESS = "UPLOAD_USER_DOCUMENT_SUCCESS";
export const UPLOAD_USER_DOCUMENT_FAILED = "UPLOAD_USER_DOCUMENT_FAILED";

export const GET_ALL_STAFFS_PENDING = "GET_ALL_STAFFS_PENDING";
export const GET_ALL_STAFFS_SUCCESS = "GET_ALL_STAFFS_SUCCESS";
export const GET_ALL_STAFFS_FAILED = "GET_ALL_STAFFS_FAILED";

export const SAVE_USER_COORDINATES_PENDING = "SAVE_USER_COORDINATES_PENDING";
export const SAVE_USER_COORDINATES_SUCCESS = "SAVE_USER_COORDINATES_SUCCESS";
export const SAVE_USER_COORDINATES_FAILED = "SAVE_USER_COORDINATES_FAILED";

export const GET_USER_LOCATION_PENDING = "GET_USER_LOCATION_PENDING";
export const GET_USER_LOCATION_SUCCESS = "GET_USER_LOCATION_SUCCESS";
export const GET_USER_LOCATION_FAILED = "GET_USER_LOCATION_FAILED";

export const ADD_USER_BANK_DETAILS_PENDING = "ADD_USER_BANK_DETAILS_PENDING";
export const ADD_USER_BANK_DETAILS_SUCCESS = "ADD_USER_BANK_DETAILS_SUCCESS";
export const ADD_USER_BANK_DETAILS_FAILED = "ADD_USER_BANK_DETAILS_FAILED";

export const GET_USER_BANK_DETAILS_PENDING = "GET_USER_BANK_DETAILS_PENDING";
export const GET_USER_BANK_DETAILS_SUCCESS = "GET_USER_BANK_DETAILS_SUCCESS";
export const GET_USER_BANK_DETAILS_FAILED = "GET_USER_BANK_DETAILS_FAILED";

export const SAVE_USER_OBSERVATION_RECORD_PENDING =
  "SAVE_USER_OBSERVATION_RECORD_PENDING";
export const SAVE_USER_OBSERVATION_RECORD_SUCCESS =
  "SAVE_USER_OBSERVATION_RECORD_SUCCESS";
export const SAVE_USER_OBSERVATION_RECORD_FAILED =
  "SAVE_USER_OBSERVATION_RECORD_FAILED";

export const UPDATE_USER_OBSERVATION_RECORD_PENDING =
  "UPDATE_USER_OBSERVATION_RECORD_PENDING";
export const UPDATE_USER_OBSERVATION_RECORD_SUCCESS =
  "UPDATE_USER_OBSERVATION_RECORD_SUCCESS";
export const UPDATE_USER_OBSERVATION_RECORD_FAILED =
  "UPDATE_USER_OBSERVATION_RECORD_FAILED";

export const GET_ALL_OBSERVATION_RECORDS_PENDING =
  "GET_ALL_OBSERVATION_RECORDS_PENDING";
export const GET_ALL_OBSERVATION_RECORDS_SUCCESS =
  "GET_ALL_OBSERVATION_RECORDS_SUCCESS";
export const GET_ALL_OBSERVATION_RECORDS_FAILED =
  "GET_ALL_OBSERVATION_RECORDS_FAILED";

export const GET_USER_OBSERVATION_RECORD_PENDING =
  "GET_USER_OBSERVATION_RECORD_PENDING";
export const GET_USER_OBSERVATION_RECORD_SUCCESS =
  "GET_USER_OBSERVATION_RECORD_SUCCESS";
export const GET_USER_OBSERVATION_RECORD_FAILED =
  "GET_USER_OBSERVATION_RECORD_FAILED";

export const ADD_NEW_USER_MEMBERSHIP_PENDING =
  "ADD_NEW_USER_MEMBERSHIP_PENDING";
export const ADD_NEW_USER_MEMBERSHIP_SUCCESS =
  "ADD_NEW_USER_MEMBERSHIP_SUCCESS";
export const ADD_NEW_USER_MEMBERSHIP_FAILED = "ADD_NEW_USER_MEMBERSHIP_FAILED";

export const GET_USER_MEMBERSHIPS_PENDING = "GET_USER_MEMBERSHIPS_PENDING";
export const GET_USER_MEMBERSHIPS_SUCCESS = "GET_USER_MEMBERSHIPS_SUCCESS";
export const GET_USER_MEMBERSHIPS_FAILED = "GET_USER_MEMBERSHIPS_FAILED";

export const ADD_NEW_USER_QUALIFICATION_PENDING =
  "ADD_NEW_USER_QUALIFICATION_PENDING";
export const ADD_NEW_USER_QUALIFICATION_SUCCESS =
  "ADD_NEW_USER_QUALIFICATION_SUCCESS";
export const ADD_NEW_USER_QUALIFICATION_FAILED =
  "ADD_NEW_USER_QUALIFICATION_FAILED";

export const GET_USER_QUALIFICATIONS_PENDING =
  "GET_USER_QUALIFICATIONS_PENDING";
export const GET_USER_QUALIFICATIONS_SUCCESS =
  "GET_USER_QUALIFICATIONS_SUCCESS";
export const GET_USER_QUALIFICATIONS_FAILED = "GET_USER_QUALIFICATIONS_FAILED";

export const ADD_NEW_USER_PERSONAL_DEVELOPMENT_PENDING =
  "ADD_NEW_USER_PERSONAL_DEVELOPMENT_PENDING";
export const ADD_NEW_USER_PERSONAL_DEVELOPMENT_SUCCESS =
  "ADD_NEW_USER_PERSONAL_DEVELOPMENT_SUCCESS";
export const ADD_NEW_USER_PERSONAL_DEVELOPMENT_FAILED =
  "ADD_NEW_USER_PERSONAL_DEVELOPMENT_FAILED";

export const UPDATE_USER_PERSONAL_DEVELOPMENT_PENDING =
  "UPDATE_USER_PERSONAL_DEVELOPMENT_PENDING";
export const UPDATE_USER_PERSONAL_DEVELOPMENT_SUCCESS =
  "UPDATE_USER_PERSONAL_DEVELOPMENT_SUCCESS";
export const UPDATE_USER_PERSONAL_DEVELOPMENT_FAILED =
  "UPDATE_USER_PERSONAL_DEVELOPMENT_FAILED";

export const DELETE_USER_PERSONAL_DEVELOPMENTS_PENDING =
  "DELETE_USER_PERSONAL_DEVELOPMENTS_PENDING";
export const DELETE_USER_PERSONAL_DEVELOPMENTS_SUCCESS =
  "DELETE_USER_PERSONAL_DEVELOPMENTS_SUCCESS";
export const DELETE_USER_PERSONAL_DEVELOPMENTS_FAILED =
  "DELETE_USER_PERSONAL_DEVELOPMENTS_FAILED";

export const GET_USER_PERSONAL_DEVELOPMENTS_PENDING =
  "GET_USER_PERSONAL_DEVELOPMENTS_PENDING";
export const GET_USER_PERSONAL_DEVELOPMENTS_SUCCESS =
  "GET_USER_PERSONAL_DEVELOPMENTS_SUCCESS";
export const GET_USER_PERSONAL_DEVELOPMENTS_FAILED =
  "GET_USER_PERSONAL_DEVELOPMENTS_FAILED";

// Policy  - done - confirm if it has either toaster or modal notification indicator in the reducers
export const CREATE_COMPANY_POLICY_PENDING = "CREATE_COMPANY_POLICY_PENDING";
export const CREATE_COMPANY_POLICY_SUCCESS = "CREATE_COMPANY_POLICY_SUCCESS";
export const CREATE_COMPANY_POLICY_FAILED = "CREATE_COMPANY_POLICY_FAILED";

export const GET_COMPANY_POLICY_PENDING = "GET_COMPANY_POLICY_PENDING";
export const GET_COMPANY_POLICY_SUCCESS = "GET_COMPANY_POLICY_SUCCESS";
export const GET_COMPANY_POLICY_FAILED = "GET_COMPANY_POLICY_FAILED";

export const UPDATE_COMPANY_POLICY_PENDING = "UPDATE_COMPANY_POLICY_PENDING";
export const UPDATE_COMPANY_POLICY_SUCCESS = "UPDATE_COMPANY_POLICY_SUCCESS";
export const UPDATE_COMPANY_POLICY_FAILED = "UPDATE_COMPANY_POLICY_FAILED";

// Accounts (Client and Staff) -
export const ADD_STAFF_ACCOUNT_PENDING = "ADD_STAFF_ACCOUNT_PENDING";
export const ADD_STAFF_ACCOUNT_SUCCESS = "ADD_STAFF_ACCOUNT_SUCCESS";
export const ADD_STAFF_ACCOUNT_FAILED = "ADD_STAFF_ACCOUNT_FAILED";

export const GET_ALL_STAFF_ACCOUNTS_PENDING = "GET_ALL_STAFF_ACCOUNTS_PENDING";
export const GET_ALL_STAFF_ACCOUNTS_SUCCESS = "GET_ALL_STAFF_ACCOUNTS_SUCCESS";
export const GET_ALL_STAFF_ACCOUNTS_FAILED = "GET_ALL_STAFF_ACCOUNTS_FAILED";

export const GET_STAFF_ACCOUNT_BY_ID_PENDING =
  "GET_STAFF_ACCOUNT_BY_ID_PENDING";
export const GET_STAFF_ACCOUNT_BY_ID_SUCCESS =
  "GET_STAFF_ACCOUNT_BY_ID_SUCCESS";
export const GET_STAFF_ACCOUNT_BY_ID_FAILED = "GET_STAFF_ACCOUNT_BY_ID_FAILED";

export const ADD_CLIENT_ACCOUNT_PENDING = "ADD_CLIENT_ACCOUNT_PENDING";
export const ADD_CLIENT_ACCOUNT_SUCCESS = "ADD_CLIENT_ACCOUNT_SUCCESS";
export const ADD_CLIENT_ACCOUNT_FAILED = "ADD_CLIENT_ACCOUNT_FAILED";

export const GET_ALL_CLIENT_ACCOUNTS_PENDING =
  "GET_ALL_CLIENT_ACCOUNTS_PENDING";
export const GET_ALL_CLIENT_ACCOUNTS_SUCCESS =
  "GET_ALL_CLIENT_ACCOUNTS_SUCCESS";
export const GET_ALL_CLIENT_ACCOUNTS_FAILED = "GET_ALL_CLIENT_ACCOUNTS_FAILED";

export const GET_CLIENT_ACCOUNT_BY_ID_PENDING =
  "GET_CLIENT_ACCOUNT_BY_ID_PENDING";
export const GET_CLIENT_ACCOUNT_BY_ID_SUCCESS =
  "GET_CLIENT_ACCOUNT_BY_ID_SUCCESS";
export const GET_CLIENT_ACCOUNT_BY_ID_FAILED =
  "GET_CLIENT_ACCOUNT_BY_ID_FAILED";

// Applications (Job applications) - done - confirm if it has either toaster or modal notification indicator in the reducers
export const CREATE_APPLICATION_PENDING = "CREATE_APPLICATION_PENDING";
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS";
export const CREATE_APPLICATION_FAILED = "CREATE_APPLICATION_FAILED";

export const GET_ALL_APPLICATIONS_PENDING = "GET_ALL_APPLICATIONS_PENDING";
export const GET_ALL_APPLICATIONS_SUCCESS = "GET_ALL_APPLICATIONS_SUCCESS";
export const GET_ALL_APPLICATIONS_FAILED = "GET_ALL_APPLICATIONS_FAILED";

// Clients (Company clients) - done - confirm if it has either toaster or modal notification indicator in the reducers
export const ADD_NEW_CLIENT_DETAILS_PENDING = "ADD_NEW_CLIENT_DETAILS_PENDING";
export const ADD_NEW_CLIENT_DETAILS_SUCCESS = "ADD_NEW_CLIENT_DETAILS_SUCCESS";
export const ADD_NEW_CLIENT_DETAILS_FAILED = "ADD_NEW_CLIENT_DETAILS_FAILED";

export const UPDATE_CLIENT_DETAILS_PENDING = "UPDATE_CLIENT_DETAILS_PENDING";
export const UPDATE_CLIENT_DETAILS_SUCCESS = "UPDATE_CLIENT_DETAILS_SUCCESS";
export const UPDATE_CLIENT_DETAILS_FAILED = "UPDATE_CLIENT_DETAILS_FAILED";

export const DELETE_CLIENT_DETAILS_PENDING = "DELETE_CLIENT_DETAILS_PENDING";
export const DELETE_CLIENT_DETAILS_SUCCESS = "DELETE_CLIENT_DETAILS_SUCCESS";
export const DELETE_CLIENT_DETAILS_FAILED = "DELETE_CLIENT_DETAILS_FAILED";

export const GET_ALL_CLIENTS_DETAILS_PENDING =
  "GET_ALL_CLIENTS_DETAILS_PENDING";
export const GET_ALL_CLIENTS_DETAILS_SUCCESS =
  "GET_ALL_CLIENTS_DETAILS_SUCCESS";
export const GET_ALL_CLIENTS_DETAILS_FAILED = "GET_ALL_CLIENTS_DETAILS_FAILED";

export const GET_ALL_STAFF_CLIENTS_DETAILS_PENDING =
  "GET_ALL_STAFF_CLIENTS_DETAILS_PENDING";
export const GET_ALL_STAFF_CLIENTS_DETAILS_SUCCESS =
  "GET_ALL_STAFF_CLIENTS_DETAILS_SUCCESS";
export const GET_ALL_STAFF_CLIENTS_DETAILS_FAILED =
  "GET_ALL_STAFF_CLIENTS_DETAILS_FAILED";

export const GET_CLIENT_DETAILS_BY_ID_PENDING =
  "GET_CLIENT_DETAILS_BY_ID_PENDING";
export const GET_CLIENT_DETAILS_BY_ID_SUCCESS =
  "GET_CLIENT_DETAILS_BY_ID_SUCCESS";
export const GET_CLIENT_DETAILS_BY_ID_FAILED =
  "GET_CLIENT_DETAILS_BY_ID_FAILED";

export const ADD_NEW_CLIENT_ABC_DETAILS_PENDING =
  "ADD_NEW_CLIENT_ABC_DETAILS_PENDING";
export const ADD_NEW_CLIENT_ABC_DETAILS_SUCCESS =
  "ADD_NEW_CLIENT_ABC_DETAILS_SUCCESS";
export const ADD_NEW_CLIENT_ABC_DETAILS_FAILED =
  "ADD_NEW_CLIENT_ABC_DETAILS_FAILED";

export const DELETE_CLIENT_ABC_DETAILS_PENDING =
  "DELETE_CLIENT_ABC_DETAILS_PENDING";
export const DELETE_CLIENT_ABC_DETAILS_SUCCESS =
  "DELETE_CLIENT_ABC_DETAILS_SUCCESS";
export const DELETE_CLIENT_ABC_DETAILS_FAILED =
  "DELETE_CLIENT_ABC_DETAILS_FAILED";

export const UPDATE_CLIENT_ABC_DETAILS_PENDING =
  "UPDATE_CLIENT_ABC_DETAILS_PENDING";
export const UPDATE_CLIENT_ABC_DETAILS_SUCCESS =
  "UPDATE_CLIENT_ABC_DETAILS_SUCCESS";
export const UPDATE_CLIENT_ABC_DETAILS_FAILED =
  "UPDATE_CLIENT_ABC_DETAILS_FAILED";

export const GET_CLIENT_ABC_DETAILS_PENDING = "GET_CLIENT_ABC_DETAILS_PENDING";
export const GET_CLIENT_ABC_DETAILS_SUCCESS = "GET_CLIENT_ABC_DETAILS_SUCCESS";
export const GET_CLIENT_ABC_DETAILS_FAILED = "GET_CLIENT_ABC_DETAILS_FAILED";

export const ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_PENDING =
  "ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_PENDING";
export const ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_SUCCESS =
  "ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_SUCCESS";
export const ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_FAILED =
  "ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_FAILED";

export const UPDATE_CLIENT_SUPPORTCARE_DETAILS_PENDING =
  "UPDATE_CLIENT_SUPPORTCARE_DETAILS_PENDING";
export const UPDATE_CLIENT_SUPPORTCARE_DETAILS_SUCCESS =
  "UPDATE_CLIENT_SUPPORTCARE_DETAILS_SUCCESS";
export const UPDATE_CLIENT_SUPPORTCARE_DETAILS_FAILED =
  "UPDATE_CLIENT_SUPPORTCARE_DETAILS_FAILED";

export const GET_CLIENT_SUPPORTCARE_DETAILS_PENDING =
  "GET_CLIENT_SUPPORTCARE_DETAILS_PENDING";
export const GET_CLIENT_SUPPORTCARE_DETAILS_SUCCESS =
  "GET_CLIENT_SUPPORTCARE_DETAILS_SUCCESS";
export const GET_CLIENT_SUPPORTCARE_DETAILS_FAILED =
  "GET_CLIENT_SUPPORTCARE_DETAILS_FAILED";

export const ADD_NEW_CLIENT_MARCHART_DETAILS_PENDING =
  "ADD_NEW_CLIENT_MARCHART_DETAILS_PENDING";
export const ADD_NEW_CLIENT_MARCHART_DETAILS_SUCCESS =
  "ADD_NEW_CLIENT_MARCHART_DETAILS_SUCCESS";
export const ADD_NEW_CLIENT_MARCHART_DETAILS_FAILED =
  "ADD_NEW_CLIENT_MARCHART_DETAILS_FAILED";

export const UPDATE_CLIENT_MARCHART_MED_NAME_PENDING =
  "UPDATE_CLIENT_MARCHART_MED_NAME_PENDING";
export const UPDATE_CLIENT_MARCHART_MED_NAME_SUCCESS =
  "UPDATE_CLIENT_MARCHART_MED_NAME_SUCCESS";
export const UPDATE_CLIENT_MARCHART_MED_NAME_FAILED =
  "UPDATE_CLIENT_MARCHART_MED_NAME_FAILED";

export const UPDATE_CLIENT_MARCHART_DOSAGE_PENDING =
  "UPDATE_CLIENT_MARCHART_DOSAGE_PENDING";
export const UPDATE_CLIENT_MARCHART_DOSAGE_SUCCESS =
  "UPDATE_CLIENT_MARCHART_DOSAGE_SUCCESS";
export const UPDATE_CLIENT_MARCHART_DOSAGE_FAILED =
  "UPDATE_CLIENT_MARCHART_DOSAGE_FAILED";

export const GET_CLIENT_MARCHART_DETAILS_PENDING =
  "GET_CLIENT_MARCHART_DETAILS_PENDING";
export const GET_CLIENT_MARCHART_DETAILS_SUCCESS =
  "GET_CLIENT_MARCHART_DETAILS_SUCCESS";
export const GET_CLIENT_MARCHART_DETAILS_FAILED =
  "GET_CLIENT_MARCHART_DETAILS_FAILED";

export const DELETE_CLIENT_MARCHART_DETAILS_PENDING =
  "DELETE_CLIENT_MARCHART_DETAILS_PENDING";
export const DELETE_CLIENT_MARCHART_DETAILS_SUCCESS =
  "DELETE_CLIENT_MARCHART_DETAILS_SUCCESS";
export const DELETE_CLIENT_MARCHART_DETAILS_FAILED =
  "DELETE_CLIENT_MARCHART_DETAILS_FAILED";

// Roaster - data
export const ADD_NEW_USER_ROASTER_DETAILS_PENDING =
  "ADD_NEW_USER_ROASTER_DETAILS_PENDING";
export const ADD_NEW_USER_ROASTER_DETAILS_SUCCESS =
  "ADD_NEW_USER_ROASTER_DETAILS_SUCCESS";
export const ADD_NEW_USER_ROASTER_DETAILS_FAILED =
  "ADD_NEW_USER_ROASTER_DETAILS_FAILED";

export const GET_USER_ROASTERS_IN_A_MONTH_PENDING =
  "GET_USER_ROASTERS_IN_A_MONTH_PENDING";
export const GET_USER_ROASTERS_IN_A_MONTH_SUCCESS =
  "GET_USER_ROASTERS_IN_A_MONTH_SUCCESS";
export const GET_USER_ROASTERS_IN_A_MONTH_FAILED =
  "GET_USER_ROASTERS_IN_A_MONTH_FAILED";

export const UPDATE_USER_ROASTER_DETAILS_PENDING =
  "UPDATE_USER_ROASTER_DETAILS_PENDING";
export const UPDATE_USER_ROASTER_DETAILS_SUCCESS =
  "UPDATE_USER_ROASTER_DETAILS_SUCCESS";
export const UPDATE_USER_ROASTER_DETAILS_FAILED =
  "UPDATE_USER_ROASTER_DETAILS_FAILED";

export const DELETE_USER_ROASTER_DETAILS_PENDING =
  "DELETE_USER_ROASTER_DETAILS_PENDING";
export const DELETE_USER_ROASTER_DETAILS_SUCCESS =
  "DELETE_USER_ROASTER_DETAILS_SUCCESS";
export const DELETE_USER_ROASTER_DETAILS_FAILED =
  "DELETE_USER_ROASTER_DETAILS_FAILED";

export const GET_ALL_USER_ROASTERS_PENDING = "GET_ALL_USER_ROASTERS_PENDING";
export const GET_ALL_USER_ROASTERS_SUCCESS = "GET_ALL_USER_ROASTERS_SUCCESS";
export const GET_ALL_USER_ROASTERS_FAILED = "GET_ALL_USER_ROASTERS_FAILED";

export const GET_ALL_ROASTERS_SHIFT_BY_DATE_PENDING =
  "GET_ALL_ROASTERS_SHIFT_BY_DATE_PENDING";
export const GET_ALL_ROASTERS_SHIFT_BY_DATE_SUCCESS =
  "GET_ALL_ROASTERS_SHIFT_BY_DATE_SUCCESS";
export const GET_ALL_ROASTERS_SHIFT_BY_DATE_FAILED =
  "GET_ALL_ROASTERS_SHIFT_BY_DATE_FAILED";

export const UPDATE_ROASTER_TO_COMPLETED_PENDING =
  "UPDATE_ROASTER_TO_COMPLETED_PENDING";
export const UPDATE_ROASTER_TO_COMPLETED_SUCCESS =
  "UPDATE_ROASTER_TO_COMPLETED_SUCCESS";
export const UPDATE_ROASTER_TO_COMPLETED_FAILED =
  "UPDATE_ROASTER_TO_COMPLETED_FAILED";

export const UPDATE_ROASTER_TO_ONGOING_PENDING =
  "UPDATE_ROASTER_TO_ONGOING_PENDING";
export const UPDATE_ROASTER_TO_ONGOING_SUCCESS =
  "UPDATE_ROASTER_TO_ONGOING_SUCCESS";
export const UPDATE_ROASTER_TO_ONGOING_FAILED =
  "UPDATE_ROASTER_TO_ONGOING_FAILED";

export const SWAP_ROASTERS_PENDING = "SWAP_ROASTERS_PENDING";
export const SWAP_ROASTERS_SUCCESS = "SWAP_ROASTERS_SUCCESS";
export const SWAP_ROASTERS_FAILED = "SWAP_ROASTERS_FAILED";

export const GET_USER_ROASTER_TOTAL_HOURS_PENDING =
  "GET_USER_ROASTER_TOTAL_HOURS_PENDING";
export const GET_USER_ROASTER_TOTAL_HOURS_SUCCESS =
  "GET_USER_ROASTER_TOTAL_HOURS_SUCCESS";
export const GET_USER_ROASTER_TOTAL_HOURS_FAILED =
  "GET_USER_ROASTER_TOTAL_HOURS_FAILED";

export const GET_ALL_ROASTERS_TOTAL_HOURS_PENDING =
  "GET_ALL_ROASTERS_TOTAL_HOURS_PENDING";
export const GET_ALL_ROASTERS_TOTAL_HOURS_SUCCESS =
  "GET_ALL_ROASTERS_TOTAL_HOURS_SUCCESS";
export const GET_ALL_ROASTERS_TOTAL_HOURS_FAILED =
  "GET_ALL_ROASTERS_TOTAL_HOURS_FAILED";

// Risk
export const ADD_NEW_RISK_ASSESSMENT_PENDING =
  "ADD_NEW_RISK_ASSESSMENT_PENDING";
export const ADD_NEW_RISK_ASSESSMENT_SUCCESS =
  "ADD_NEW_RISK_ASSESSMENT_SUCCESS";
export const ADD_NEW_RISK_ASSESSMENT_FAILED = "ADD_NEW_RISK_ASSESSMENT_FAILED";

export const ADD_RISK_ASSESSMENT_REVIEW_PENDING =
  "ADD_RISK_ASSESSMENT_REVIEW_PENDING";
export const ADD_RISK_ASSESSMENT_REVIEW_SUCCESS =
  "ADD_RISK_ASSESSMENT_REVIEW_SUCCESS";
export const ADD_RISK_ASSESSMENT_REVIEW_FAILED =
  "ADD_RISK_ASSESSMENT_REVIEW_FAILED";

export const GET_ALL_RISK_ASSESSMENT_RECORDS_PENDING =
  "GET_ALL_RISK_ASSESSMENT_RECORDS_PENDING";
export const GET_ALL_RISK_ASSESSMENT_RECORDS_SUCCESS =
  "GET_ALL_RISK_ASSESSMENT_RECORDS_SUCCESS";
export const GET_ALL_RISK_ASSESSMENT_RECORDS_FAILED =
  "GET_ALL_RISK_ASSESSMENT_RECORDS_FAILED";

// Complains (Clients and Staffs)
export const ADD_NEW_COMPLAIN_PENDING = "ADD_NEW_COMPLAIN_PENDING";
export const ADD_NEW_COMPLAIN_SUCCESS = "ADD_NEW_COMPLAIN_SUCCESS";
export const ADD_NEW_COMPLAIN_FAILED = "ADD_NEW_COMPLAIN_FAILED";

export const GET_ALL_COMPLAINS_PENDING = "GET_ALL_COMPLAINS_PENDING";
export const GET_ALL_COMPLAINS_SUCCESS = "GET_ALL_COMPLAINS_SUCCESS";
export const GET_ALL_COMPLAINS_FAILED = "GET_ALL_COMPLAINS_FAILED";

export const GET_ALL_STAFFS_COMPLAINS_PENDING =
  "GET_ALL_STAFFS_COMPLAINS_PENDING";
export const GET_ALL_STAFFS_COMPLAINS_SUCCESS =
  "GET_ALL_STAFFS_COMPLAINS_SUCCESS";
export const GET_ALL_STAFFS_COMPLAINS_FAILED =
  "GET_ALL_STAFFS_COMPLAINS_FAILED";

export const GET_ALL_CLIENTS_COMPLAINS_PENDING =
  "GET_ALL_CLIENTS_COMPLAINS_PENDING";
export const GET_ALL_CLIENTS_COMPLAINS_SUCCESS =
  "GET_ALL_CLIENTS_COMPLAINS_SUCCESS";
export const GET_ALL_CLIENTS_COMPLAINS_FAILED =
  "GET_ALL_CLIENTS_COMPLAINS_FAILED";

// Supplies - done - confirm if it has either toaster or modal notification indicator in the reducers
export const ADD_NEW_SUPPLIES_DETAILS_PENDING =
  "ADD_NEW_SUPPLIES_DETAILS_PENDING";
export const ADD_NEW_SUPPLIES_DETAILS_SUCCESS =
  "ADD_NEW_SUPPLIES_DETAILS_SUCCESS";
export const ADD_NEW_SUPPLIES_DETAILS_FAILED =
  "ADD_NEW_SUPPLIES_DETAILS_FAILED";

export const ADD_NEW_TRANSACTIONS_DETAILS_PENDING =
  "ADD_NEW_TRANSACTIONS_DETAILS_PENDING";
export const ADD_NEW_TRANSACTIONS_DETAILS_SUCCESS =
  "ADD_NEW_TRANSACTIONS_DETAILS_SUCCESS";
export const ADD_NEW_TRANSACTIONS_DETAILS_FAILED =
  "ADD_NEW_TRANSACTIONS_DETAILS_FAILED";

export const GET_ALL_SUPPLIES_DETAILS_PENDING =
  "GET_ALL_SUPPLIES_DETAILS_PENDING";
export const GET_ALL_SUPPLIES_DETAILS_SUCCESS =
  "GET_ALL_SUPPLIES_DETAILS_SUCCESS";
export const GET_ALL_SUPPLIES_DETAILS_FAILED =
  "GET_ALL_SUPPLIES_DETAILS_FAILED";

export const DELETE_A_SUPPLIES_DETAILS_PENDING =
  "DELETE_A_SUPPLIES_DETAILS_PENDING";
export const DELETE_A_SUPPLIES_DETAILS_SUCCESS =
  "DELETE_A_SUPPLIES_DETAILS_SUCCESS";
export const DELETE_A_SUPPLIES_DETAILS_FAILED =
  "DELETE_A_SUPPLIES_DETAILS_FAILED";

// Requests (User requests) - done - confirm if it has either toaster or modal notification indicator in the reducers
export const ADD_NEW_REQUEST_PENDING = "ADD_NEW_REQUEST_PENDING";
export const ADD_NEW_REQUEST_SUCCESS = "ADD_NEW_REQUEST_SUCCESS";
export const ADD_NEW_REQUEST_FAILED = "ADD_NEW_REQUEST_FAILED";

export const GET_USER_REQUESTS_PENDING = "GET_USER_REQUESTS_PENDING";
export const GET_USER_REQUESTS_SUCCESS = "GET_USER_REQUESTS_SUCCESS";
export const GET_USER_REQUESTS_FAILED = "GET_USER_REQUESTS_FAILED";

export const GET_ALL_REQUESTS_PENDING = "GET_ALL_REQUESTS_PENDING";
export const GET_ALL_REQUESTS_SUCCESS = "GET_ALL_REQUESTS_SUCCESS";
export const GET_ALL_REQUESTS_FAILED = "GET_ALL_REQUESTS_FAILED";

export const GET_ALL_REQUESTS_BY_DATE_PENDING =
  "GET_ALL_REQUESTS_BY_DATE_PENDING";
export const GET_ALL_REQUESTS_BY_DATE_SUCCESS =
  "GET_ALL_REQUESTS_BY_DATE_SUCCESS";
export const GET_ALL_REQUESTS_BY_DATE_FAILED =
  "GET_ALL_REQUESTS_BY_DATE_FAILED";

export const UPDATE_REQUEST_TO_GRANTED_PENDING =
  "UPDATE_REQUEST_TO_GRANTED_PENDING";
export const UPDATE_REQUEST_TO_GRANTED_SUCCESS =
  "UPDATE_REQUEST_TO_GRANTED_SUCCESS";
export const UPDATE_REQUEST_TO_GRANTED_FAILED =
  "UPDATE_REQUEST_TO_GRANTED_FAILED";

export const UPDATE_REQUEST_TO_REJECT_PENDING =
  "UPDATE_REQUEST_TO_REJECT_PENDING";
export const UPDATE_REQUEST_TO_REJECT_SUCCESS =
  "UPDATE_REQUEST_TO_REJECT_SUCCESS";
export const UPDATE_REQUEST_TO_REJECT_FAILED =
  "UPDATE_REQUEST_TO_REJECT_FAILED";

// Notice -done - confirm if it has either toaster or modal notification indicator in the reducers
export const ADD_INFO_TO_NOTICE_BOARD_PENDING =
  "ADD_INFO_TO_NOTICE_BOARD_PENDING";
export const ADD_INFO_TO_NOTICE_BOARD_SUCCESS =
  "ADD_INFO_TO_NOTICE_BOARD_SUCCESS";
export const ADD_INFO_TO_NOTICE_BOARD_FAILED =
  "ADD_INFO_TO_NOTICE_BOARD_FAILED";

export const GET_ALL_INFO_ON_NOTICE_BOARD_PENDING =
  "GET_ALL_INFO_ON_NOTICE_BOARD_PENDING";
export const GET_ALL_INFO_ON_NOTICE_BOARD_SUCCESS =
  "GET_ALL_INFO_ON_NOTICE_BOARD_SUCCESS";
export const GET_ALL_INFO_ON_NOTICE_BOARD_FAILED =
  "GET_ALL_INFO_ON_NOTICE_BOARD_FAILED";

export const TEST_PUSH_NOTIFICATION_PENDING = "TEST_PUSH_NOTIFICATION_PENDING";
export const TEST_PUSH_NOTIFICATION_SUCCESS = "TEST_PUSH_NOTIFICATION_SUCCESS";
export const TEST_PUSH_NOTIFICATION_FAILED = "TEST_PUSH_NOTIFICATION_FAILED";

// Fleet (Company's fleet) - done - confirm if it has either toaster or modal notification indicator in the reducers
export const ADD_NEW_VEHICLE_DETAILS_PENDING =
  "ADD_NEW_VEHICLE_DETAILS_PENDING";
export const ADD_NEW_VEHICLE_DETAILS_SUCCESS =
  "ADD_NEW_VEHICLE_DETAILS_SUCCESS";
export const ADD_NEW_VEHICLE_DETAILS_FAILED = "ADD_NEW_VEHICLE_DETAILS_FAILED";

export const DELETE_VEHICLE_DETAILS_PENDING = "DELETE_VEHICLE_DETAILS_PENDING";
export const DELETE_VEHICLE_DETAILS_SUCCESS = "DELETE_VEHICLE_DETAILS_SUCCESS";
export const DELETE_VEHICLE_DETAILS_FAILED = "ADD_NEW_VEHICLE_DETAILS_FAILED";

export const UPDATE_VEHICLE_DETAILS_PENDING = "UPDATE_VEHICLE_DETAILS_PENDING";
export const UPDATE_VEHICLE_DETAILS_SUCCESS = "UPDATE_VEHICLE_DETAILS_SUCCESS";
export const UPDATE_VEHICLE_DETAILS_FAILED = "UPDATE_VEHICLE_DETAILS_FAILED";

export const GET_ALL_VEHICLES_PENDING = "GET_ALL_VEHICLES_PENDING";
export const GET_ALL_VEHICLES_SUCCESS = "GET_ALL_VEHICLES_SUCCESS";
export const GET_ALL_VEHICLES_FAILED = "GET_ALL_VEHICLES_FAILED";

export const GET_ALL_UNASSIGNED_VEHICLES_PENDING =
  "GET_ALL_UNASSIGNED_VEHICLES_PENDING";
export const GET_ALL_UNASSIGNED_VEHICLES_SUCCESS =
  "GET_ALL_UNASSIGNED_VEHICLES_SUCCESS";
export const GET_ALL_UNASSIGNED_VEHICLES_FAILED =
  "GET_ALL_UNASSIGNED_VEHICLES_FAILED";

// Trainings (Company's trainings) - done - confirm if it has either toaster or modal notification indicator in the reducers
export const ADD_NEW_USER_TRAINING_EVENT_PENDING =
  "ADD_NEW_USER_TRAINING_EVENT_PENDING";
export const ADD_NEW_USER_TRAINING_EVENT_SUCCESS =
  "ADD_NEW_USER_TRAINING_EVENT_SUCCESS";
export const ADD_NEW_USER_TRAINING_EVENT_FAILED =
  "ADD_NEW_USER_TRAINING_EVENT_FAILED";

export const GET_ALL_USER_TRAININGS_PENDING = "GET_ALL_USER_TRAININGS_PENDING";
export const GET_ALL_USER_TRAININGS_SUCCESS = "GET_ALL_USER_TRAININGS_SUCCESS";
export const GET_ALL_USER_TRAININGS_FAILED = "GET_ALL_USER_TRAININGS_FAILED";

export const DELETE_USER_TRAININGS_PENDING = "DELETE_USER_TRAININGS_PENDING";
export const DELETE_USER_TRAININGS_SUCCESS = "DELETE_USER_TRAININGS_SUCCESS";
export const DELETE_USER_TRAININGS_FAILED = "DELETE_USER_TRAININGS_FAILED";

export const UPDATE_USER_TRAININGS_PENDING = "UPDATE_USER_TRAININGS_PENDING";
export const UPDATE_USER_TRAININGS_SUCCESS = "UPDATE_USER_TRAININGS_SUCCESS";
export const UPDATE_USER_TRAININGS_FAILED = "UPDATE_USER_TRAININGS_FAILED";

// Upload - done - confirm if it has either toaster or modal notification indicator in the reducers
export const UPLOAD_DOCUMENT_AND_GET_URL_PENDING =
  "UPLOAD_DOCUMENT_AND_GET_URL_PENDING";
export const UPLOAD_DOCUMENT_AND_GET_URL_SUCCESS =
  "UPLOAD_DOCUMENT_AND_GET_URL_SUCCESS";
export const UPLOAD_DOCUMENT_AND_GET_URL_FAILED =
  "UPLOAD_DOCUMENT_AND_GET_URL_FAILED";

export const UPLOAD_DOCUMENT_PENDING = "UPLOAD_DOCUMENT_PENDING";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAILED = "UPLOAD_DOCUMENT_FAILED";

export const GET_ALL_UPLOADED_DOCUMENTS_PENDING =
  "GET_ALL_UPLOADED_DOCUMENTS_PENDING";
export const GET_ALL_UPLOADED_DOCUMENTS_SUCCESS =
  "GET_ALL_UPLOADED_DOCUMENTS_SUCCESS";
export const GET_ALL_UPLOADED_DOCUMENTS_FAILED =
  "GET_ALL_UPLOADED_DOCUMENTS_FAILED";

// Chat - done - confirm if it has either toaster or modal notification indicator in the reducers
export const CREATE_NEW_GROUP_CHAT_PENDING = "CREATE_NEW_GROUP_CHAT_PENDING";
export const CREATE_NEW_GROUP_CHAT_SUCCESS = "CREATE_NEW_GROUP_CHAT_SUCCESS";
export const CREATE_NEW_GROUP_CHAT_FAILED = "CREATE_NEW_GROUP_CHAT_FAILED";

export const ADD_GROUP_CHAT_PARTICIPANT_PENDING =
  "ADD_GROUP_CHAT_PARTICIPANT_PENDING";
export const ADD_GROUP_CHAT_PARTICIPANT_SUCCESS =
  "ADD_GROUP_CHAT_PARTICIPANT_SUCCESS";
export const ADD_GROUP_CHAT_PARTICIPANT_FAILED =
  "ADD_GROUP_CHAT_PARTICIPANT_FAILED";

export const POST_MESSAGE_IN_GROUP_CHAT_PENDING =
  "POST_MESSAGE_IN_GROUP_CHAT_PENDING";
export const POST_MESSAGE_IN_GROUP_CHAT_SUCCESS =
  "POST_MESSAGE_IN_GROUP_CHAT_SUCCESS";
export const POST_MESSAGE_IN_GROUP_CHAT_FAILED =
  "POST_MESSAGE_IN_GROUP_CHAT_FAILED";

export const POST_IMAGE_IN_GROUP_CHAT_PENDING =
  "POST_IMAGE_IN_GROUP_CHAT_PENDING";
export const POST_IMAGE_IN_GROUP_CHAT_SUCCESS =
  "POST_IMAGE_IN_GROUP_CHAT_SUCCESS";
export const POST_IMAGE_IN_GROUP_CHAT_FAILED =
  "POST_IMAGE_IN_GROUP_CHAT_FAILED";

export const GET_ALL_GROUP_CHATS_PENDING = "GET_ALL_GROUP_CHATS_PENDING";
export const GET_ALL_GROUP_CHATS_SUCCESS = "GET_ALL_GROUP_CHATS_SUCCESS";
export const GET_ALL_GROUP_CHATS_FAILED = "GET_ALL_GROUP_CHATS_FAILED";

export const GET_USER_GROUP_CHATS_PENDING = "GET_USER_GROUP_CHATS_PENDING";
export const GET_USER_GROUP_CHATS_SUCCESS = "GET_USER_GROUP_CHATS_SUCCESS";
export const GET_USER_GROUP_CHATS_FAILED = "GET_USER_GROUP_CHATS_FAILED";

export const ESTABLISH_NEW_CONNECTION_PENDING =
  "ESTABLISH_NEW_CONNECTION_PENDING";
export const ESTABLISH_NEW_CONNECTION_SUCCESS =
  "ESTABLISH_NEW_CONNECTION_SUCCESS";
export const ESTABLISH_NEW_CONNECTION_FAILED =
  "ESTABLISH_NEW_CONNECTION_FAILED";

export const POST_MESSAGE_IN_TWO_USERS_CHAT_PENDING =
  "POST_MESSAGE_IN_TWO_USERS_CHAT_PENDING";
export const POST_MESSAGE_IN_TWO_USERS_CHAT_SUCCESS =
  "POST_MESSAGE_IN_TWO_USERS_CHAT_SUCCESS";
export const POST_MESSAGE_IN_TWO_USERS_CHAT_FAILED =
  "POST_MESSAGE_IN_TWO_USERS_CHAT_FAILED";

export const POST_IMAGE_IN_TWO_USERS_CHAT_PENDING =
  "POST_IMAGE_IN_TWO_USERS_CHAT_PENDING";
export const POST_IMAGE_IN_TWO_USERS_CHAT_SUCCESS =
  "POST_IMAGE_IN_TWO_USERS_CHAT_SUCCESS";
export const POST_IMAGE_IN_TWO_USERS_CHAT_FAILED =
  "POST_IMAGE_IN_TWO_USERS_CHAT_FAILED";

export const GET_USER_CHATS_PENDING = "GET_USER_CHATS_PENDING";
export const GET_USER_CHATS_SUCCESS = "GET_USER_CHATS_SUCCESS";
export const GET_USER_CHATS_FAILED = "GET_USER_CHATS_FAILED";

export const GET_CHATS_BY_ID_PENDING = "GET_CHATS_BY_ID_PENDING";
export const GET_CHATS_BY_ID_SUCCESS = "GET_CHATS_BY_ID_SUCCESS";
export const GET_CHATS_BY_ID_FAILED = "GET_CHATS_BY_ID_FAILED";
