import * as actionTypes from "../constants";
import { toast } from "react-toastify";

const initialState = {
  currentUser: {},
  user: {},
  users: [],
  staffs: [],
  loading: false,
  error: false,
  staffCoordinates: null,
  signUpError: false,
  logInError: false,
  isUserLoggedInError: false,
  isUserLoggedIn: false,
  isRegistered: false,
  updatedProfile: false,
  recoverError: false,
  changeLoading: false,
  profileLoading: false,
  CheckUserLoading: false,
  getUserQualificationDetailsError: false,
  userQualificationDetails: false,
  addUserQualificationDetailsError: false,
  newUserQualificationDetails: false,
  getUserMembershipDetailsError: false,
  userMembershipDetails: false,
  addUserMembershipDetailsError: false,
  newUserMembershipDetails: false,
  getUserBankDetailsError: false,
  userBankDetails: false,
  addUserBankDetailsError: false,
  newUserBankDetails: false,
  getAllStaffCoordinatesError: false,
  allStaffCoordinates: false,
  saveStaffCoordinatesError: false,
  getAllStaffsDetailsError: false,
  uploadUserDocumentError: false,
  updateUserProfileImage: false,
  uploadUserDocument: false
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_PENDING:
      toast.info("Logging you out...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.LOGOUT_SUCCESS:
      toast.success("Logged out successfully!");
      return {
        ...state,
        currentUser: {},
        user: {},
        isUserLoggedIn: false,
        loading: false,
        error: false
      };
    case actionTypes.LOGOUT_FAILED:
      toast.error(`An error occurred: ${action.payload.message}`);
      return {
        ...state,
        loading: false,
        error: true,
        logoutError: action.payload.message
      };
    case actionTypes.LOGIN_PENDING:
      // toast.info("Logging in...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.LOGIN_SUCCESS:
      // toast.success("Login Successfully!");
      return {
        ...state,
        currentUser: action.payload.data,
        user: action.payload.data,
        isUserLoggedIn: true,
        loading: false,
        error: false
      };
    case actionTypes.LOGIN_FAILED:
      toast.error(
        `An error occurred: ${
          action.payload.response === undefined
            ? action.payload.error
            : action.payload.response.data.error
        }`
      );
      return {
        ...state,
        loading: false,
        error: true,
        logInError:
          action.payload.response === undefined
            ? action.payload.error
            : action.payload.response.data.error
      };
    case actionTypes.GET_USER_BY_ID_PENDING:
      // toast.info("Fetching Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_BY_ID_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        currentUser: action.payload.data,
        user: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_BY_ID_FAILED:
      toast.error("Failed to fetch details...");
      return {
        ...state,
        loading: false,
        error: true,
        getUserDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_USER_DETAILS_PENDING:
      toast.info("Updating User Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_USER_DETAILS_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        currentUser: action.payload.data,
        user: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_USER_DETAILS_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateUserDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_USER_PROFILE_IMAGE_PENDING:
      toast.info("Updating User Image...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_USER_PROFILE_IMAGE_SUCCESS:
      // toast.info("Image updated successfully!");
      return {
        ...state,
        updateUserProfileImage: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_USER_PROFILE_IMAGE_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateUserImageError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPLOAD_USER_DOCUMENT_PENDING:
      toast.info("Uploading Document...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPLOAD_USER_DOCUMENT_SUCCESS:
      // toast.info("Document uploaded successfully!");
      return {
        ...state,
        uploadUserDocument: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPLOAD_USER_DOCUMENT_FAILED:
      toast.error("Failed to upload document...");
      return {
        ...state,
        loading: false,
        error: true,
        uploadUserDocumentError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_STAFFS_PENDING:
      // toast.info("Fetching Staffs Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_STAFFS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        staffs: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_ALL_STAFFS_FAILED:
      toast.error("Failed to fetch details...");
      return {
        ...state,
        loading: false,
        error: true,
        getAllStaffsDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.SAVE_USER_COORDINATES_PENDING:
      toast.info("Saving User Coordinates...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.SAVE_USER_COORDINATES_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        staffCoordinates: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.SAVE_USER_COORDINATES_FAILED:
      toast.error("Failed to save user coordinates...");
      return {
        ...state,
        loading: false,
        error: true,
        saveStaffCoordinatesError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_USER_BANK_DETAILS_PENDING:
      toast.info("Saving User Bank Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_USER_BANK_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        newUserBankDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_USER_BANK_DETAILS_FAILED:
      toast.error("Failed to save user bank details...");
      return {
        ...state,
        loading: false,
        error: true,
        addUserBankDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_BANK_DETAILS_PENDING:
      toast.info("Fetching User Bank Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_BANK_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        userBankDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_BANK_DETAILS_FAILED:
      toast.error("Failed to fetch user bank details...");
      return {
        ...state,
        loading: false,
        error: true,
        getUserBankDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_USER_MEMBERSHIP_PENDING:
      toast.info("Saving User Membership Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_USER_MEMBERSHIP_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        newUserMembershipDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_NEW_USER_MEMBERSHIP_FAILED:
      toast.error("Failed to save user membership details...");
      return {
        ...state,
        loading: false,
        error: true,
        addUserMembershipDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_MEMBERSHIPS_PENDING:
      toast.info("Fetching User Membership Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_MEMBERSHIPS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        userMembershipDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_MEMBERSHIPS_FAILED:
      toast.error("Failed to fetch user membership details...");
      return {
        ...state,
        loading: false,
        error: true,
        getUserMembershipDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_USER_QUALIFICATION_PENDING:
      toast.info("Saving User Qualification Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_USER_QUALIFICATION_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        newUserQualificationDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_NEW_USER_QUALIFICATION_FAILED:
      toast.error("Failed to save user qualification details...");
      return {
        ...state,
        loading: false,
        error: true,
        addUserQualificationDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_QUALIFICATIONS_PENDING:
      toast.info("Fetching User Qualification Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_QUALIFICATIONS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        userQualificationDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_QUALIFICATIONS_FAILED:
      toast.error("Failed to fetch user qualification details...");
      return {
        ...state,
        loading: false,
        error: true,
        getUserQualificationDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}

export default userReducer;
