import * as actionTypes from "../constants";
import { toast } from "react-toastify";

export const initialState = {
  loading: "",
  error: "",
  newRequest: "",
  userRequest: "",
  updateRequestGranted: "",
  updateRequestReject: "",
  RequestError: ""
};

export default function RequestReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_NEW_REQUEST_PENDING:
      toast.info("Sending Request ");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_REQUEST_SUCCESS:
      toast.success("Request Sent Successfully");
      return {
        ...state,
        newRequest: action.payload
      };
    case actionTypes.ADD_NEW_REQUEST_FAILED:
      toast.error("Request failed to send");
      return {
        ...state,
        loading: false,
        error: true,
        RequestError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_USER_REQUESTS_PENDING:
      toast.info("loading..");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_USER_REQUESTS_SUCCESS:
      return {
        ...state,
        userRequest: action.payload
      };
    case actionTypes.GET_USER_REQUESTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RequestError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_REQUESTS_PENDING:
      toast.info("loading..");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_REQUESTS_SUCCESS:
      return {
        ...state,
        userRequest: action.payload
      };
    case actionTypes.GET_ALL_REQUESTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RequestError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_REQUESTS_BY_DATE_PENDING:
      toast.info("loading..");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_REQUESTS_BY_DATE_SUCCESS:
      return {
        ...state,
        userRequest: action.payload
      };
    case actionTypes.GET_ALL_REQUESTS_BY_DATE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        RequestError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_REQUEST_TO_GRANTED_PENDING:
      toast.info("loading..");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_REQUEST_TO_GRANTED_SUCCESS:
      toast.success("Request has been Approved");
      return {
        ...state,
        updateRequestGranted: action.payload
      };
    case actionTypes.UPDATE_REQUEST_TO_GRANTED_FAILED:
      toast.error("Approval was not successful");
      return {
        ...state,
        loading: false,
        error: true,
        RequestError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_REQUEST_TO_REJECT_PENDING:
      toast.info("loading..");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_REQUEST_TO_REJECT_SUCCESS:
      toast.success("Request has been Approved");
      return {
        ...state,
        updateRequestReject: action.payload
      };
    case actionTypes.UPDATE_REQUEST_TO_REJECT_FAILED:
      toast.error("Approval was not successful");
      return {
        ...state,
        loading: false,
        error: true,
        RequestError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
