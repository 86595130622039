import * as actionTypes from "../constants";

export const initialState = {
  loading: "",
  error: "",
  Application: "",
  newApplication: "",
  ApplicationError: ""
};

export default function ApplicationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_APPLICATION_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        newApplication: action.payload
      };
    case actionTypes.CREATE_APPLICATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ApplicationError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_APPLICATIONS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        Application: action.payload
      };
    case actionTypes.GET_ALL_APPLICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        ApplicationError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
