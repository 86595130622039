import * as actionTypes from "../constants";

export const initialState = {
  loading: "",
  error: "",
  Training: "",
  newTraining: "",
  TrainingError: ""
};

export default function TrainingReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_NEW_USER_TRAINING_EVENT_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_USER_TRAINING_EVENT_SUCCESS:
      return {
        ...state,
        newTraining: action.payload
      };
    case actionTypes.ADD_NEW_USER_TRAINING_EVENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        TrainingError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.DELETE_USER_TRAININGS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_USER_TRAININGS_SUCCESS:
      return {
        ...state,
        newTraining: action.payload
      };
    case actionTypes.DELETE_USER_TRAININGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        TrainingError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_USER_TRAININGS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_USER_TRAININGS_SUCCESS:
      return {
        ...state,
        newTraining: action.payload
      };
    case actionTypes.UPDATE_USER_TRAININGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        TrainingError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_USER_TRAININGS_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_USER_TRAININGS_SUCCESS:
      return {
        ...state,
        Training: action.payload.data
      };
    case actionTypes.GET_ALL_USER_TRAININGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        TrainingError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
