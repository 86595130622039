import * as actionTypes from "../constants";
// import { toast } from "react-toastify";

export const initialState = {
  loading: "",
  error: "",
  userPayroll: "",
  allStaffPayroll: "",
  userPayrollGraph: "",
  PayrollError: ""
};

export default function PayrollReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_STAFF_PAYROLL_PENDING:
      // toast.info('Deleteing Payroll Details..')
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_STAFF_PAYROLL_SUCCESS:
      // toast.success('Payroll Delected Successfully.')
      return {
        ...state,
        allStaffPayroll: action.payload.data
      };
    case actionTypes.GET_ALL_STAFF_PAYROLL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        PayrollError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_STAFF_PAYROLL_PENDING:
      // toast.info('Deleteing Payroll Details..')
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_STAFF_PAYROLL_SUCCESS:
      // toast.success('Payroll Delected Successfully.')
      return {
        ...state,
        userPayroll: action.payload.data
      };
    case actionTypes.GET_STAFF_PAYROLL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        PayrollError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_STAFF_PAYROLL_GRAPH_PENDING:
      // toast.info('Deleteing Payroll Details..')
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_STAFF_PAYROLL_GRAPH_SUCCESS:
      // toast.success('Payroll Delected Successfully.')
      return {
        ...state,
        userPayrollGraph: action.payload.data
      };
    case actionTypes.GET_STAFF_PAYROLL_GRAPH_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        PayrollError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
}
