import * as actionTypes from "../constants";
import { toast } from "react-toastify";

const initialState = {
  clientDetails: null,
  clients: null,
  staffClient: null,
  clientMarchartDetails: null,
  clientABCDetails: null,
  clientSupportCareDetails: null,
  updateClientSupportCareDetails: false,
  newClientSupportCareDetails: false,
  updateClientABCDetails: false,
  newClientABCDetails: false,
  deleteClientMarchartDetails: false,
  updateClientMarchartDetails: false,
  updateClientMarchartDetailsDosage: false,
  newClientMarchartDetails: false,
  users: null
};

const ClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_STAFF_CLIENTS_DETAILS_PENDING:
      toast.info("Fetching Users Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_STAFF_CLIENTS_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        staffClient: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_ALL_STAFF_CLIENTS_DETAILS_FAILED:
      toast.error("Failed to fetch details...");
      return {
        ...state,
        loading: false,
        error:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_USERS_PROFILE_PENDING:
      toast.info("Fetching Users Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_USERS_PROFILE_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        users: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_ALL_USERS_PROFILE_FAILED:
      toast.error("Failed to fetch details...");
      return {
        ...state,
        loading: false,
        error: true,
        getAllUsersDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_CLIENT_DETAILS_PENDING:
      toast.info("Saving Client Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_DETAILS_SUCCESS:
      toast.info("Client Added successfully!");
      return {
        ...state,
        clientDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_DETAILS_FAILED:
      toast.error("Failed to save client details...");
      return {
        ...state,
        loading: false,
        error: true,
        addClientError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.DELETE_CLIENT_DETAILS_PENDING:
      toast.info("deletng Client Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_CLIENT_DETAILS_SUCCESS:
      toast.info("Clients Details deleting successfully!");
      return {
        ...state,
        clientDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_CLIENT_DETAILS_FAILED:
      toast.error("Failed to delete details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateClientDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_CLIENT_DETAILS_PENDING:
      toast.info("Updating Client Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_DETAILS_SUCCESS:
      toast.info("Clients Details updated successfully!");
      return {
        ...state,
        clientDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_DETAILS_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateClientDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_ALL_CLIENTS_DETAILS_PENDING:
      toast.info("Fetching All Clients Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_ALL_CLIENTS_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        clients: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_ALL_CLIENTS_DETAILS_FAILED:
      toast.error("Failed to fetch clients details...");
      return {
        ...state,
        loading: false,
        error: true,
        getAllClientsDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_CLIENT_DETAILS_BY_ID_PENDING:
      toast.info("Fetching Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_CLIENT_DETAILS_BY_ID_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        clientDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_CLIENT_DETAILS_BY_ID_FAILED:
      toast.error("Failed to fetch client details...");
      return {
        ...state,
        loading: false,
        error: true,
        getClientDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_CLIENT_MARCHART_DETAILS_PENDING:
      toast.info("Saving Client Mar Chart Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_MARCHART_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        newClientMarchartDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_MARCHART_DETAILS_FAILED:
      toast.error("Failed to save client marchart details...");
      return {
        ...state,
        loading: false,
        error: true,
        addClientMarchartDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_CLIENT_MARCHART_MED_NAME_PENDING:
      toast.info("Updating Marchart Medication...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_MARCHART_MED_NAME_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        updateClientMarchartDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_MARCHART_MED_NAME_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateMarchartMedNameError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_CLIENT_MARCHART_DOSAGE_PENDING:
      toast.info("Updating Marchart Medication Dosage...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_MARCHART_DOSAGE_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        updateClientMarchartDetailsDosage: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_MARCHART_DOSAGE_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateMarchartDosageError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_CLIENT_MARCHART_DETAILS_PENDING:
      toast.info("Fetching Marchart Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_CLIENT_MARCHART_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        clientMarchartDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_CLIENT_MARCHART_DETAILS_FAILED:
      toast.error("Failed to fetch details...");
      return {
        ...state,
        loading: false,
        error: true,
        getMarchartDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.DELETE_CLIENT_MARCHART_DETAILS_PENDING:
      toast.info("Deleting Client's Marchart Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_CLIENT_MARCHART_DETAILS_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        deleteClientMarchartDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_CLIENT_MARCHART_DETAILS_FAILED:
      toast.error("Failed to delete details...");
      return {
        ...state,
        loading: false,
        error: true,
        deleteMarchartDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_CLIENT_ABC_DETAILS_PENDING:
      toast.info("Saving Client ABC Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_ABC_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        newClientABCDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_ABC_DETAILS_FAILED:
      toast.error("Failed to save client's ABC details...");
      return {
        ...state,
        loading: false,
        error: true,
        addABCDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_CLIENT_ABC_DETAILS_PENDING:
      toast.info("Updating Client ABC Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_ABC_DETAILS_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        updateClientABCDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_ABC_DETAILS_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateABCDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.DELETE_CLIENT_ABC_DETAILS_PENDING:
      toast.info("Updating Client ABC Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_CLIENT_ABC_DETAILS_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        updateClientABCDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_CLIENT_ABC_DETAILS_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateABCDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_CLIENT_ABC_DETAILS_PENDING:
      toast.info("Fetching Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_CLIENT_ABC_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        clientABCDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_CLIENT_ABC_DETAILS_FAILED:
      toast.error("Failed to fetch ABC details...");
      return {
        ...state,
        loading: false,
        error: true,
        getABCDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_PENDING:
      toast.info("Saving Client Support Care Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        newClientSupportCareDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.ADD_NEW_CLIENT_SUPPORTCARE_DETAILS_FAILED:
      toast.error("Failed to save client's support care details...");
      return {
        ...state,
        loading: false,
        error: true,
        addSupportCareDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.UPDATE_CLIENT_SUPPORTCARE_DETAILS_PENDING:
      toast.info("Updating Support Care Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_SUPPORTCARE_DETAILS_SUCCESS:
      // toast.info("Details updated successfully!");
      return {
        ...state,
        updateClientSupportCareDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_CLIENT_SUPPORTCARE_DETAILS_FAILED:
      toast.error("Failed to update details...");
      return {
        ...state,
        loading: false,
        error: true,
        updateSupportCareDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    case actionTypes.GET_CLIENT_SUPPORTCARE_DETAILS_PENDING:
      toast.info("Fetching Details...");
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.GET_CLIENT_SUPPORTCARE_DETAILS_SUCCESS:
      // toast.info("Details fetched successfully!");
      return {
        ...state,
        clientSupportCareDetails: action.payload.data,
        loading: false,
        error: false
      };
    case actionTypes.GET_CLIENT_SUPPORTCARE_DETAILS_FAILED:
      toast.error("Failed to fetch details...");
      return {
        ...state,
        loading: false,
        error: true,
        getSupportCareDetailsError:
          action.payload.response === undefined
            ? action.payload.message
            : action.payload.response.data.message
      };
    default:
      return state;
  }
};

export default ClientsReducer;
