import React from "react";
import { toast } from "react-toastify";
import "./error.css";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    toast.error("An error occurred:" + error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="c">
          <div className="shadow mx-auto py-5 bg-white col-sm-6">
            <div className="_404">Error</div>
            <div className="_1 my-2">We're sorry</div>
            <div className="_2 my-2">something's gone wrong</div>
            <a className="btn error text-light" href="/">
              BACK TO Home
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
